function Footer() {
  return (<footer className="bg-gray-900" aria-labelledby="footer-heading">
    <h2 id="footer-heading" className="sr-only">
      Footer
    </h2>
    <div className="max-w-md mx-auto pt-12 px-4 sm:max-w-7xl sm:px-6 lg:pt-16 lg:px-8">
      <div className="mt-12 py-8">
        <p className="text-base text-gray-400 xl:text-center">&copy; 2021 Instadart, Inc. All rights reserved.</p>
        <p className="text-base text-gray-400 xl:text-center">Abuse Report: abuse@instadart.com - NOC / Tech:
          arin-sys@instadart.com.</p>
      </div>
    </div>
  </footer>)
}

export default Footer;
