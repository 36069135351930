import Head from "next/head";

export default function CommonHead() {
  return (<Head>
    <title>AS40818 - Accelerated network between public clouds.</title>
    <link rel="icon" href="/favicon.ico"/>
    <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
    <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
    <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
    <link rel="manifest" href="/site.webmanifest"/>
    <meta name="msapplication-TileColor" content="#da532c"/>
    <meta name="theme-color" content="#ffffff"/>
    <meta
      name="description"
      content="Accelerated network between public clouds."
    />
    <meta name="twitter:card" content="summary_large_image"/>
    <meta name="twitter:site" content="@AS40818"/>
    <meta name="twitter:creator" content="@AS40818"/>
    <meta
      name="twitter:image"
      content="https://as40818.net/img/instadart-og.png"
    />
    <meta name="twitter:url" content="https://as40818.net/"/>
    <meta property="og:url" content="https://as40818.net/"/>
    <meta property="og:type" content="website"/>
    <meta
      property="og:title"
      content="AS40818 - Accelerated network between public clouds."
    />
    <meta
      property="og:description"
      content="AS40818 - Accelerated network between public clouds."
    />
    <meta
      property="og:image"
      content="https://as40818.net/img/instadart-og.png"
    />
    <meta
      property="og:image:alt"
      content="AS40818 - Accelerated network between public clouds."
    />
    <meta property="og:locale" content="en_IE"/>
    <meta property="og:site_name" content="AS40818"/>
  </Head>)
}
